.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4; /* or any other suitable background */
}

.auth-container {
    text-align: center;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px; /* Adjust width as needed */
}

.company-logo {
    max-width: 150px;
    margin-bottom: 20px;
}

.login-input-container {
    margin-bottom: 20px;
}

.login-input-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.login-button-container {
    margin-top: 20px;
}

.login-btn {
    background-color: #000000; /* Google blue */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: 100%; /* Full width buttons */
    margin-bottom: 10px; /* Spacing between buttons */
}

.login-btn:hover {
    background-color: #333333; /* Slightly lighter on hover */
}

.login-btn:focus {
    outline: none;
}
