.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.terms-modal {
  background: white;
  padding: 0px; /* Increased padding for better spacing */
  border-radius: 10px;
  width: 50%; /* Adjust width as necessary */
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  margin: auto; /* Center the modal in the overlay */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content */
}

.terms-text {
  margin-bottom: 20px;
  text-align: center; /* Center the text */
}

.terms-form h3 {
  text-align: center; /* Center the header text */
}

.terms-form button {
  display: block;
  width: 100%;
  margin-top: 20px;
}

.checkbox-container {
  display: flex;
  align-items: center; /* Vertically aligns the checkbox with the text */
  margin-bottom: 10px;
}

.checkbox-container p {
  margin: 0; /* Remove default margin of <p> to align properly */
  padding-left: 10px; /* Space between checkbox and text */
}
