/* Style the buttons container, ensuring it's more specific to your app's structure */
  .buttons-container {
    display: flex;
    justify-content: flex-start;
    align-items: left; 
    margin-left: 250px;
    padding: 5px 0;
  }
  
  /* Style the buttons */
  .buttons-container .button {
    margin-right: 10px;
  }
  
  /* Remove margin for the last button */
  .buttons-container .button:last-child {
    margin-right: 0;
  }