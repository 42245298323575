/* Ensure the grid container fills the parent container */
.ag-grid-container {
  margin-left: 240px;
  margin-top: 7%;
  margin-right: 20px;
  height: calc(100vh - 7% - 20px); /* Adjust height calculation as needed */
}

/* To center the header text, you might need to override Ag-Grid's default styles */
.ag-theme-alpine .ag-header-cell-label {
  justify-content: center !important; /* Adding !important to override default styles */
}

/* Center the cell contents */
.ag-theme-alpine .ag-cell-value {
  display: flex;
  justify-content: center !important; /* Adding !important to override default styles */
  align-items: center;
}
