.auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-input-container,
.signup-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  /* Adjust the width as necessary */
}
