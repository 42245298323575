.drawer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 220px;
}

.MuiDrawer-paper {
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 220px;
}

.editable-chat-name {
    border-bottom: 1px solid transparent; /* Create a bottom border */
    padding: 8px; /* Match ListItemText padding */
    min-height: 24px; /* Minimum height */
  }
  
  .editable-chat-name:focus {
    border-bottom: 1px solid #000; /* Change border color on focus */
    outline: none; /* Remove the default outline */
  }
  