  .pricing-table-container {
    margin-top: -80px; /* Puxa o container para mais perto do topo */
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    gap: 20px;
  }

  .pricing-plan {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the start of the container */
    align-items: center; /* Center items horizontally */
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    overflow: visible; /* Ensure that content can overflow */
    margin-bottom: 20px;
    width: 100%; /* Take full width of its container */
    min-height: 300px; /* Minimum height */
    height: auto; /* Height adjusts to content */
}


  .ribbon {
    position: absolute;
    top: -10px; /* Adjust as necessary */
    right: -10px; /* Adjust as necessary */
    background: #666;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
  }
  
  .plan-title {
    margin: 10px 0;
    font-size: 24px;
    color: #333;
  }
  
  .plan-description {
    color: #666;
    font-size: 16px;
  }
  
  .plan-badge img {
    margin-top: 10px;
    width: 80px; /* Adjust as necessary */
    height: auto;
  }
  
  .plan-price {
    margin: 20px 0;
    font-size: 32px;
    font-weight: bold;
  }
  
  .plan-action {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 15px 70px; /* Increased padding for a larger button */
    margin-top: 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px; /* Larger font size for the text on the button */
    display: inline-block; /* Ensure it's treated as an inline block element */
    width: auto; /* Adjust width as needed */
    box-sizing: border-box; /* Include padding and border in the width */
  }
  
  .plan-action:hover {
    background-color: #0056b3;
  }
  
  
  .plan-features {
    overflow-y: auto; /* Permite scroll vertical se necessário */
    max-height: 100px; /* Limita a altura da lista de características */
}

  
  .plan-features li {
    margin-bottom: 10px;
  }
  